.logobar{
    width: 100%;
    background-color: white ;
    width: 100% ;
    display: inline-grid;
    grid-template-columns: 2fr 1fr;
}
.logobarlogo{ 

}
.logobarinfo{ 

}
.menubar{ 
    height: 68px;
    width: 100% ;
    display: inline-grid;
    grid-template-columns: 2fr 1fr;
}
.menubutton{
    font-size: 18px;
    font-weight: 400;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    border-bottom: 7px transparent;
    text-decoration-color :rgb(255, 255, 255);
    text-decoration-line: none;
    text-decoration-style: solid;
    text-decoration-thickness: auto;
    padding-top: 21px;
}
.menubutton:hover{
    border-bottom: 7px solid rgba(255, 255, 255, 0.5);
}
.menuiconbutton{

    font-weight: 400;
    border: none;
    color: white;
    padding: 15px 32px;
    padding-bottom: 4px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    border-bottom: 7px transparent;
    text-decoration-color :rgb(255, 255, 255);
    text-decoration-line: none;
    text-decoration-style: solid;
    text-decoration-thickness: auto;
    
    padding-top: 22px;
}
.menuiconbutton:hover{
    border-bottom: 7px solid rgba(255, 255, 255, 0.5);
}
.menuadmin{
    position: absolute; 
    width:80%;
    color:white;
    margin-left: 10px;
    padding:20px;
    z-index: 999;
}