body {
  margin: 0;
  font-family: "Fira Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size:15px;
  line-height: 1.3;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3f3f3;
}
*{
  line-height: 1.4
}
[contenteditable] {
  outline: 0px solid transparent;
}
h1{
  font-size: 26px;
  color: rgb(0, 105, 143);

}
h2{
  font-size: 22px;
  color: rgb(0, 105, 143);
}
h3{

  font-size: 18px;
  color: rgb(0, 105, 143);
}
.contentspacing{
  padding-bottom: 100px;
  min-height: 700px;
}
.linkbutton{
  cursor: pointer;
  border-bottom: 3px solid transparent;

}
.linkbutton:hover{
  border-bottom: 3px solid silver;
}
code {
  font-family: "Fira Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
}
input{
  font-size: 15px;
}
textarea{
  font-size: 15px;
}
option{
  font-size: 15px;
}
select{
  font-size: 15px;
}
.card{
  background-color: white;
  float:left;
  width: 250px;
  height: 440px;
  min-height: 440px;
  margin:20px;
  border-radius: 5px 5px 5px 5px;
  cursor: pointer;
  position:relative;
}
.card:hover{
  
}
.card-line{
  width: 80px;
  margin-left: 20px;
  border-bottom: 2px solid rgb(147, 156, 163);;
}
.card-image{
  background-color:#ff8036;
  background-repeat: no-repeat;
  background-size: cover;
  width:100%;
  height: 100px;
}
.card-content{
  color:rgb(61, 61, 61);
  padding:10px;
  padding-left: 20px;
  height: 240px;
  line-height: 25px;
  overflow:hidden;
  width: 220px;
}
.card-footer{
  height: 40px;
}
.card-footer-link{
  overflow: hidden;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 3; 
  width:80px;
  display: inline-block;
  transition: bottom 0.1s ease-in-out;
}
.card-footer-link-active{
  background-color: rgb(147, 156, 163);
  color: white;
  padding: 5px;
}
.optionfeedback{
  padding:8px;
  margin:8px;
  font-style: italic;
  font-weight: 700;
  color:rgb(0, 105, 143);
  border:1px solid rgb(0, 105, 143);
}
.showDelayed{
  animation: 1s fadeInDelayed;
  animation-fill-mode: forwards;
  visibility: hidden;
}
@keyframes fadeInDelayed {
  70% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}
.veditorcheckbox{
  cursor: pointer;
}

.veditortext{
  width:100%;
  border:0px;
  min-height: 25px;
  height: 25px;
  border-bottom:1px solid #aa0009;
  font-size: 13px;
}

.veditor table{
    border-spacing:0px;
}

.veditor td{
    border: 1px solid #e4e4e4;
    padding:10px;
    font-size: 13px !important;
}

.veditor td p {
    font-size: 13px  !important;
}

.veditortextarea{
  width:100%;
  resize: none;
  overflow: hidden;
  min-height: 25px;
  height: 25px;
  border:0px;
  border-bottom:1px solid #aa0009;
  font-size: 13px;
}

.veditorselect{
  height: 30px;
  width: 100%;
  padding: 5px;
  border: none;
  border-bottom: 1px solid #aa0009;
  background-color:white;
  margin: 0px;
}
  