 
.footer{
    clear:both;   
    width: 100%;
    position:relative;
    min-height: 300px;
}

.footercontent{
    padding:30px;
    display: inline-grid;
    grid-template-columns: 2fr 1fr;
}
.footercontenttext{
    line-height: 25px;
}
.copyright{
    text-align: center;
    padding: 50px;
    color:gray;
    clear:'both';
    margin:50px;
}